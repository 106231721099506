.NoticiaWebView{
    height: 100vh;
    width: 100%;
    padding-top: 40px;
    background-color: var(--background);
    .container{
        height: 100%;
        background-color: var(--whiteDiferente);
        padding-bottom: 40px;
        border-radius: 20px 20px 0px 0px;
        iframe{
            border-radius: 20px 20px 0px 0px;
            height: 100%;
            width: 100%;
            border: none;
        }
    }
}