.Post{
    display: flex;
    // flex-direction: column;
    padding: 32px 12px;
    border-bottom: 1px solid black;
    max-width: 100%;
    .conteudo{
        display: flex;
        gap: 12px;
        flex-grow: 1;
        .fotoPerfil{
            background-image: url('../../assets/62b8395d-4b5c-4efa-ba2f-088d359eb284.png');
            background-position: center;
            background-size: cover;
            width: 50px;
            height: 50px;
            border-radius: 100%;
        }
        .textosPost{
            display: flex;
            flex-direction: column;
            gap: 5px;
            flex-grow: 1;
            max-width: 100%;
            .info{
                display: flex;
                gap: 20px;
                align-items: center;
                .repetido{
                    color: red;
                    font-size: .8rem;
                }
                .nome{
                    font-size: .8rem;
                }
            }
            .texto{
                font-size: .9rem;
                max-width: 100%;
                // max-width: 265px;

            }
        }
    }

}