.ChatPrivado{
    background-color: var(--whiteDiferente);
    min-height: 100vh;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding-bottom: 70px;
    .mensagens{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;
    }
}