.Contato{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding: 5px 30px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid lightgrey;
    .info{
        display: flex;
        // gap: 30px;
        .coluna2{
            color: var(--mediumGrey);
            font-size: .8rem;
        }
        
    }
    .left{
        display: flex;
        align-items: center;
        gap: 30px;
        .foto{
            min-width: 50px;
            min-height: 50px;
            border-radius: 100;
            background-image: url('../../assets/62b8395d-4b5c-4efa-ba2f-088d359eb284.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 100px;
        }
        .coluna1{
            display: flex;
            flex-direction: column;
            gap: 4px;
            h1{
                font-size: 1.2rem;
                font-weight: 100;
            }
            p{
                color: var(--mediumGrey);
                font-size: .8rem;
            }
        }

    }
}