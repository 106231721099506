.ItemBottomMenu{
    display: flex;
    align-items: center;
    border: none;
    gap: 10px;
    background-color: inherit;
    min-width: 260px;
    .icon{
        height: 22px;
        width: 22px;
        color: var(--mediumBlue);
    }
    .text-arrow{
        display: flex;
        align-items: center;
        // gap: 70px;
        flex-grow: 1;
        justify-content: space-between;
        .coluna-textos{
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 4px;
            h1{
                font-size: 0.7rem;
                font-weight: 100;
            }
            p{
                color: var(--mediumGrey);
                font-size: .6rem;
            }


        }
    }

    .dRYlrY{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.sair{
    h1{
        color: red;
    }     

}