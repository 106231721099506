.Home{
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    align-items: center;
    padding-top: 20px;
    min-height: 100vh;
    gap: 20px;
    .container{
        background-color: var(--whiteDiferente);
        border-radius: 20px 20px 0px 0px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        padding-bottom: 80px;
        .carregando{
            margin: auto;
            padding: 10px;
            color: red;
            font-size: 1.2rem;
        }
    }
    >img{
        width: 50px;
        height: 50px;
    }
    .bottomItens{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: fixed;
        bottom: 0;
        align-items: end;
        gap: 10px;
        .NavBarFooter{
            position: initial;
        }
    }

}