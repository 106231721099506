.NavBarFooter{
    display: flex;
    width: 100%;
    justify-content: space-around;
    background-color: white;
    padding: 15px;
    border-radius: 20px 20px 0px 0px;
    transition: ease .5s;
    position: fixed;
    bottom: 0;
    button{
        padding: 7px 12px 5px;
        border-radius: 10px;
        border: none;
        background-color: white;
        cursor: pointer;
    }
    .principal{
        background-color: var(--lightBlue);
    }
}