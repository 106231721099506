.conserto {
    position: relative;
    min-height: 100vh;
    // max-height: 100vh;
    overflow: hidden;
    .zindex{
        z-index: 0;
    }
    .Perfil {
        background-color: var(--background);
        min-height: 100vh;
        min-width: 100%;
        display: flex;
        flex-direction: column; 
        justify-content: space-between;
        position: absolute;
        z-index: 1;
        transition: ease .5s;
        padding-top: 20px;
        .conig {
            display: flex;
            width: 100%;
            align-items: end;
            flex-direction: column;
            padding: 20px;
        }

        .container {
            background-color: var(--whiteDiferente);
            display: flex;
            flex-direction: column;
            align-items: center;
            // min-height: 70vh;
            justify-content: space-between;
            flex-grow: .8;
            gap: 30px;
            .info {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 86%;
                gap: 4px;
                padding-top: 60px;
                .textos {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    // width: 100%;
                    p {
                        font-size: .9rem;
                        text-align: center;
                        line-height: 1.4;
                        color: gray;
                    }

                    .username {
                        font-size: .8rem;
                    }
                    .complementos{
                        display: flex;
                        justify-content: center;
                        gap: 5px;
                        .campo{
                            color: red;
                        }
                    }
                }
                .foto {
                    background-image: url('../../assets/62b8395d-4b5c-4efa-ba2f-088d359eb284.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100px;
                    height: 100px;
                    @media (max-height: 600px) {
                        width: 100px;
                        height: 100px;
                    }
                    position: absolute;
                    // margin-top: calc(0% - 80px);
                    top: -30%;
                }
            }
            .posts-container {
                padding-bottom: 70px;
                display: flex;
                flex-direction: column;
                min-width: 100%;
                max-height: 100vh; /* Defina uma altura máxima igual à altura da tela */
                overflow: auto; /* Adicione a propriedade overflow para permitir a rolagem quando necessário */
                flex-grow: 1;
                padding-bottom: 70px;
                >div{
                    .conteudo{
                        .textosPost{
                            display: flex;
                            gap: 0px;
                            // flex-direction: row;
                            .info{
                                padding: 0px;
                                align-items:start;
                                gap: 10px;

                            }
                        }
                    }
                }
            }
            
        }

    }
}