.MensagemRecebida {
    background-color: white;
    max-width: 40%;
    border-radius: 20px;
    font-size: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: none;
  
    h1 {
      font-size: 1.2rem;
    }
  
    p {
      width: 100%; /* Alterado para width */
      overflow-wrap: break-word; /* Adicionado para quebrar a linha */
    }
  }
  