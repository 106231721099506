.Cadastro{
    // min-width: 100%;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    // align-items: center;
    background-color: white;
    max-height: 100vh;
    min-height: 100vh;
    justify-content: space-around;
    align-items: center;
    padding: 20px;    
        img{
            width: 20%;
        }
        .erros{
            color: red;
        }
        form{
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            min-width: 100%;
            flex-grow: 0.5;
            justify-content: space-between;
            .form-inputs{
                min-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .esqueciSenha{
                color: white;
                padding-top: 15px;
                text-decoration: none;
            }
            
        }
        p{
            color: white;
        }
        label{
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            min-width: 100%;
            // color: white;
        }
        .inputLogin{
            border: 2px solid var(--mediumBlue);
        }
    }
    .containerBottom{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        padding: 20px 20px 0px 20px;
        flex-grow: 0.15;
        button:nth-child(2){
            background-color: var(--mediumBlue);
            border: 3px solid var(--mediumBlue);
        }
    }
