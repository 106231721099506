.ButtonBigBlue{
    background-color: var(--mediumBlue);
    border: none;
    padding: 10px 35px;
    font-size: 1.5rem;
    border-radius: 35px;
    color: white;
    min-width: 100%;
    cursor: pointer;
}
