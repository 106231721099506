.MensagemEnviada{
    background-color: var(--mediumBlue);
    max-width: 40%;
    border-radius: 20px;
    font-size: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    margin-left: auto;
    h1 {
        font-size: 1.2rem;
        width: 100%; /* Alterado para width */
        overflow-wrap: break-word; /* Adicionado para quebrar a linha */
      }
    
      p {
        width: 100%; /* Alterado para width */
        overflow-wrap: break-word; /* Adicionado para quebrar a linha */
      }
}

