.AlterarFotoPerfil{
    background-color: var(--background);
    min-height: 100vh;
    min-width: 100%;
    padding-top: 40px;
    display: flex;
    .container{
        background-color: var(--whiteDiferente);
        display: flex;
        flex-direction: column;
        min-width: 100%;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        .top{
            min-width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 10px 10px 0px 10px;
            align-items: center;
            .ArrowBackOutline{
                color: black;
                max-width: 22px;
                max-height: 22px;
            }
            .nada{
                width: 1px;
                height: 1px;
            }
        }
        .alterar{
            display: flex;
            flex-direction: column;
            width: 70%;
            gap: 15px;
            p{
                font-size: 1.1rem;
                font-weight: bold;
            }
            .options{
                display: flex;
                flex-direction: column;
                border: 2px solid var(--mediumBlue);
                border-radius: 20px;
                background-color: var(--whiteDiferente);
                padding: 30px 10px;
                gap: 20px;
                .linha{
                    display: flex;
                    gap: 10px;
                    button{
                        border-radius: 20px;
                        border: none;
                        background-color: var(--whiteDiferente);
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .select{
                        border: 2px solid var(--mediumBlue) !important;
                        padding: 4px;
                    }
                }
            }
        }
        .buttons{
            display: flex;
            gap: 30px;
            padding-bottom: 40px;
            min-width: 100%;
            justify-content: space-evenly;
            @media screen and (min-width: 700px) {
                button{
                    min-width: 230px;
                    padding: 20px;
                    font-size: 1.6rem;
                    border-radius: 40px;
                }
                
            }
            
            @media screen and (max-width: 350px) {
                button{
                    min-width: 100px;
                    // padding: 20px;
                    // font-size: 1.6rem;
                    // border-radius: 40px;
                }
                
            }
            button:nth-child(1){
                // min-width: none;
                background-color: var(--mediumRed);
                border: red;
                color: white;
            }
            button:nth-child(2){
                background-color: white;
            }
            
            
        }
    }
    .erro{
        color: var(--mediumRed);
    }
}