.MenuTopChat{
    display: flex;
    background-color: white;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-radius: 0px 0px 20px 20px;
    .conteudo{
        padding: 10px;
        display: flex;
        gap: 20px;
        align-items: center;
        .fotoPerfil{
            background-image: url('../../assets/62b8395d-4b5c-4efa-ba2f-088d359eb284.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 100px;
            width: 50px;
            height: 50px;
        }
        p{
            font-size: .8rem;
            color: var(--mediumGrey);
        }
        h1{
            font-weight: 400;
            font-size: 1.2rem;
        }
        
    }
}