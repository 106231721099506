
:root{
 --background: #F3436E;
 --mediumGrey: #9A9E9C;
 --whiteDiferente: #f5f3f3;
 --lightBlue: #FFB4C6;
 --mediumBlue: #FD3D6C;
 --mediumRed: #d31e1e;
 --linkBlue: #4C9EEB;
 *{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-size: 12pt;    
    font-family: "Inter", sans-serif;
    .cursor{
        cursor: pointer;
    }
    .sumido{
        display: none;
    }
}
}