.PostEmpresa{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    border-bottom: 1px solid black;
    padding-bottom: 20px;
    
    .Post{
        border: none;
        max-width: 100%;
        padding-bottom: 0px;
        width: 100%;
    }
    Link{
        text-decoration: none;
        color: var(--linkBlue);
    }
}