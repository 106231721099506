.BarraDePesquisa{
    background-color: white;
    padding: 6px;
    padding-right: 10px;
    border: none;
    border-radius: 10px;
    display: flex;
    min-width: 80%;
    input{
        width: 100%;
        border: none;
        font-size: 1rem;
        padding-left: 20px;
        &:focus{
            outline: none;
        }
        // height: 100%;
    }
    .Search{
        // padding: 100px;
    }
}