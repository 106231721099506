.NoticiaComponente{
    display: flex;
    padding: 30px;
    min-width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
    .colunaEsquerda{
        display: flex;
        flex-direction: column;
        gap: 15px;
        .titulo{
            font-size: 1.8rem;
            font-weight: bold;
        }
        .outro{
            font-size: 1.3rem;
            color: grey;
        }
    }
    .data{
        font-size: 1.3rem;
        color: grey;
    }
}