.FazerPost{
    height: 100vh;
    min-width: 100%;
    // display: flex;
    padding-top: 50px;
    background-color: var(--background);
    .container{
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        background-color: var(--whiteDiferente);
        height: 100%;
        .logo{
            max-width: 70px;
            max-height: 70px;
        }
        .inputPost{
            display: flex;
            align-items: center;
            gap: 20px;
            min-width: 100%;
            .fotoPerfil{
                background-image: url('../../assets/62b8395d-4b5c-4efa-ba2f-088d359eb284.png');
                width: 40px;
                height: 40px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
            h1{
                font-size: 1.1rem;
                font-weight: 100;
                color: gray;
            }
        }
        .buttons{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .buttons > button:nth-child(1){
            border: none;
        }
        .buttons > button:nth-child(2){
            background-color: var(--lightBlue);
            border: none;
            color: white;
            min-width: 110px;
            font-size: 1.1rem;
            padding: 0px;
        }
        .textField{
            width: 100%;
            padding: 20px;
            background-color: var(--lightBlue);
            color: var(--mediumBlue);
            display: flex;
            flex-direction: column;
            gap: 20px;
            textarea{
                width: 100%;
                // height: 100%;
                background-color: inherit;
                border: none;
                color: var(--mediumBlue);
            }
            textarea:focus{
                outline: none;
            }
        }
    }

}