.MenuEnviarMensagem{
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 20px 20px 0px 0px;
    gap: 5px;
    position: fixed;
    bottom: 0px;
    input{
        padding: 20px;
        font-size: 1.2rem;  
        border: none;
        border-radius: 20px;
        background-color: lightgray;
        min-width: 90%;
    }
    span{
        min-width: 100%;
        min-width: 5%;
        svg{
            width: 100%;
            height: 60px;
        }
    }
}