.EsqueciSenha{
    // min-width: 100%;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    // align-items: center;
    background-color: #8DAAD4;
    max-height: 100vh;
    min-height: 100vh;
    align-items: center;
    span{
        display: flex;
        padding: 10px;
        width: 100%;
        .ArrowBackOutline{
            color: white;
            width: 30px;
            max-height: 30px;
            
        }
    }     
    .container-top{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        min-width: 100%;
        h1{
            color: white;
            font-size: 2rem;
            text-align: center;
        }
        img{
            min-width: 50%;
        }
        p{
            padding-top: 15px;
            width: 100%;
            color: white;
        }
    
    }
        form{
            display: flex;
            flex-direction: column;
            gap: 12px;
            // align-items: center;
            min-width: 100%;
            flex-grow: 0.9;
            justify-content: space-between;
            padding: 20px;
            .form-inputs{
                min-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        label{
            display: flex;
            flex-direction: column;
            gap: 5px;
            min-width: 100%;
            color: white;
        }
    }
    
    


